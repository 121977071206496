import React, { useState, useEffect } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utils/axiosConfig';
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const Department = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const DepartmentId = location.state?.departmentId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setActivePage(translate('Department'));

        const fetchData = async () => {
            try {
                setLoading(true);
                if (DepartmentId > 0) {
                    const response = await axiosInstance.get('/department/' + DepartmentId);
                    setInputValues(response.data);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchData();
    }, [setActivePage, translate, DepartmentId]);

    const handleCancel = () => {
        navigate('/settings/configuration?tab=' + translate('departments'));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear validation error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!inputValues.name.trim()) {
            errors.name = translate('Name is required');
            isValid = false;
        } else if (inputValues.name.trim().length < 2) {
            errors.name = translate('Name must be at least 2 characters long');
            isValid = false;
        } else if (inputValues.name.trim().length > 200) {
            errors.name = translate('Name cannot be longer than 200 characters');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/department', inputValues);

            const locationId = response.data;
            setSuccessMessage(translate('Department successfully saved!'));
            setLoading(false);
            setTimeout(() => setSuccessMessage(''), 2500);
            navigate('/settings/department', { state: { locationId: locationId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };

    const handleDelete = () => {
        setSelectedDepartment(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedDepartment) {
            await axiosInstance.delete('/department/' + selectedDepartment.id);
            navigate('/settings/configuration?tab=' + translate('departments'));
        }
        setIsModalDeleteOpen(false);
    };

    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : 'xx'}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div className={`error-alert ${isVisible ? 'visible' : ''}`}>
                    <FiAlertCircle /> {error}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="name">{translate('Name')}:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={inputValues.name}
                                onChange={handleChange}
                                className={`form-control ${validationErrors.name ? 'is-invalid' : ''}`}
                                maxLength="200"
                                required
                            />
                            {validationErrors.name && (
                                <div className="invalid-feedback">
                                    {validationErrors.name}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-md-3">
                        {parseInt(inputValues.id) > 0 &&
                            <button type="button" onClick={handleDelete} className="remove-button">
                                {translate('Delete')}
                            </button>
                        }
                    </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                        <button type="button" onClick={handleCancel} className="cancel-button">
                            {translate('Cancel')}
                        </button>
                    </div>
                </div>
            </form>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove department')}
                message={translate('Are you sure you want to delete this department "{{departmentName}}"?', { departmentName: selectedDepartment?.name })}
            />
        </>
    );
};

export default Department;