import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import LoginPage from './components/LoginPage';
import ForgotPasswordPage from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import Configuration from './components/configuration/Settings/Configuration';
import Boarding from './components/configuration/Boarding/Boarding'; 

import Preboardingflows from './components/flows/Preboardingflows';
import Preboardingdetails from './components/flows/Preboardingdetails';
import Preboardingemployee from './components/flows/Preboardingemployee';
import Preboardingflow from './components/flows/Preboardingflow';
import Preboardingoverview from './components/flows/Preboardingoverview';

import Preboardingflowconfig from './components/configuration/Boarding/Preboardingflow';
import Onboardingflowconfig from './components/configuration/Boarding/Onboardingflow';
import Reboardingflowconfig from './components/configuration/Boarding/Reboardingflow';
import Offboardingflowconfig from './components/configuration/Boarding/Offboardingflow';

import Account from './components/configuration/Settings/Account';
import Languages from './components/configuration/Settings/Languages';
import Language from './components/configuration/Settings/Language';
import Messages from './components/configuration/Settings/Messages';
import Message from './components/configuration/Settings/Message';
import Location from './components/configuration/Settings/Location';
import Department from './components/configuration/Settings/Department';
import Employee from './components/configuration/Settings/Employee';

import Tasks from './components/Tasks';

import Elearnings from './components/elearning/Elearnings';
import './styling/App.css';

function App() {
    const [activePage, setActivePage] = useState('My to-do');

    return (
        <I18nextProvider i18n={i18n}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="/reset-password" element={<ResetPassword />} />

                        <Route path="/" element={<ProtectedRoute><Dashboard activePage={activePage} setActivePage={setActivePage} /></ProtectedRoute>}>
                            <Route path="tasks" element={<ProtectedRoute><Tasks setActivePage={setActivePage} /></ProtectedRoute>} />

                            <Route path="elearning" element={<ProtectedRoute><Elearnings /></ProtectedRoute>} />
                             
                            <Route path="settings">
                                <Route path="configuration" element={<ProtectedRoute><Configuration setActivePage={setActivePage} /></ProtectedRoute>} />
                                <Route path="languages" element={<ProtectedRoute><Languages setActivePage={setActivePage} /></ProtectedRoute>} />
                                <Route path="language" element={<ProtectedRoute><Language setActivePage={setActivePage} /></ProtectedRoute>} />
                                <Route path="boarding" element={<ProtectedRoute><Boarding setActivePage={setActivePage} /></ProtectedRoute>} />

                                <Route path="boarding">
                                    <Route path="Onboardingflow" element={<ProtectedRoute><Onboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="Preboardingflow" element={<ProtectedRoute><Preboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="Reboardingflow" element={<ProtectedRoute><Reboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                    <Route path="Offboardingflow" element={<ProtectedRoute><Offboardingflowconfig setActivePage={setActivePage} /></ProtectedRoute>} />
                                </Route>

                                <Route path="account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
                                <Route path="messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
                                <Route path="message" element={<ProtectedRoute><Message /></ProtectedRoute>} />
                                <Route path="location" element={<ProtectedRoute><Location /></ProtectedRoute>} />
                                <Route path="employee" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
                                <Route path="department" element={<ProtectedRoute><Department /></ProtectedRoute>} />
                           </Route>

                            <Route path="boarding">
                                <Route path="preboarding" element={<ProtectedRoute><Preboardingflows /></ProtectedRoute>} />
                                <Route path="preboarding/details" element={<ProtectedRoute><Preboardingdetails /></ProtectedRoute>} />
                                <Route path="preboarding/employee" element={<ProtectedRoute><Preboardingemployee /></ProtectedRoute>} />
                                <Route path="preboarding/flow" element={<ProtectedRoute><Preboardingflow /></ProtectedRoute>} />
                                <Route path="preboarding/overview" element={<ProtectedRoute><Preboardingoverview /></ProtectedRoute>} />


                                <Route path="onboarding" element={<ProtectedRoute><div>Onboarding Component</div></ProtectedRoute>} />
                                <Route path="reboarding" element={<ProtectedRoute><div>Reboarding Component</div></ProtectedRoute>} />
                                <Route path="offboarding" element={<ProtectedRoute><div>Offboarding Component</div></ProtectedRoute>} />
                            </Route>
                        </Route>
                    </Routes>
                </div>
            </Router>
        </I18nextProvider>
    );
}

// ProtectedRoute component to check if user is authenticated
function ProtectedRoute({ children }) {
    const isAuthenticated = localStorage.getItem('accessToken') !== null;
    return isAuthenticated ? children : <Navigate to="/login" replace />;
}

export default App;