import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import { FiInfo } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";

const Preboardingoverview = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate, i18n } = useTranslation();
    const flowid = location.state?.flowid ?? 0;
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [tasks, setTasks] = useState(false);

    const [inputValues, setInputValues] = useState({
        currentstep: 1,
        typeid: 1,
        template: 0,
        id: 0,
        startdate: '',
        name: '',
        firstname: '',
        lastname: '',
        preferredname: '',
        gender: '',
        dayofbirth: '',
        placeofbirth: '',
        civilstatus: '',
        personalemail: '',
        personalphone: '',
        nationality: ''
    }); 


    const fetchTasks = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/flow/boardingtasks/' + flowid);
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }, [flowid]);

    const fetchItem = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/flow/getboarding/' + flowid);
            setInputValues(response.data);
            setActivePage(translate('Preboarding workflow - ' + response.data.name));
        } catch (err) {
            console.error('An error occurred while retrieving the data :', err);
        } finally {
            setLoading(false);
        }
    }, [flowid, setActivePage, translate]);

    useEffect(() => {
        setActivePage(translate('New preboarding'));
        if (flowid !== 0) {
            fetchItem();
            fetchTasks();
        } else {
            setLoading(false);
        }
    }, [setActivePage, translate, fetchTasks, fetchItem, flowid]);

    const handleCancel = useCallback(() => {
        navigate('/boarding/preboarding');
    }, [navigate]);


    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : ''}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}


            {tasks && tasks.length > 0 ? (
                tasks.map((task) => (
                    <div className="row" key={`row-${task.id}`}> 
                        <div className="col-3 ps-5">
                                <div className="task-item" style={{ borderColor: '#666' }}>
                                    <div className="icon-space">
                                        {(task.carriedout === '1900-01-01' && <FaRegCircle style={{ color: '#666' }} />)}
                                        {(task.carriedout !== '1900-01-01' && <FaRegCircleCheck style={{ color: '#7bd17b' }} />)}
                                
                                </div>
                                <div className="task-content">
                                    <div className="task-header">
                                            <span className="task-space-task">{task.name}</span>

                                    </div>
                                        <span className="task-space-name mb-4">{task.carriedoutbyname}</span>
                                </div>
                            </div>
                        </div>
                            <div className="col-1"><span className="task-space-date"> 
                                {(task.carriedout === '1900-01-01' && task.startdate)}
                                {(task.carriedout !== '1900-01-01' && task.carriedout)}
                                 </span></div>
                        <div className="col-8"></div>
                    </div> 
                ))
            ) : (
                <div className="row">
                        <div className="col-12 text-center">
                            <p>{loading === false ? translate('No Task Found') : translate('Loading')}</p>
                    </div>
                </div>
            )}



            <div className="row">
                <div className="col-md-8"> 
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
            </div>

        </>
    );

};

export default Preboardingoverview;