import React, { useState, useEffect, useCallback } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useNavigate, useLocation } from 'react-router-dom';

const GuideTour = () => {
    const [runTour, setRunTour] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');

    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
    }, []);

    const steps = [
        {
            target: 'body',
            title: 'Our awesome projects',
            content: (
                <>
                    <h6 style={{ marginTop: 10 }}>Weekly magic on your inbox</h6>
                    <input
                        name="email"
                        onChange={handleEmailChange}
                        placeholder="Type your email"
                        type="email"
                        style={{
                            width: '100%',
                            padding: '8px',
                            marginTop: '10px',
                            borderRadius: '4px',
                            border: '1px solid #ccc'
                        }}
                    />
                </>
            ),
            placement: 'center',
            path: '/'
        },
        {
            target: '.user-menu-container',
            content: 'Hier staan jouw initialen.',
            placement: 'left',
            path: '/settings/configuration?tab=accounts'
        },
        {
            target: 'header',
            content: 'Dit is de hoofdbanner van onze site. Hier vind je belangrijke informatie en acties.',
            placement: 'bottom',
        },
        {
            target: 'nav',
            content: 'Dit is het hoofdmenu. Hier kun je naar verschillende secties van de site navigeren.',
            placement: 'bottom',
        },
        //{
        //    target: 'main',
        //    content: 'Dit is het hoofdgedeelte van de pagina. Hier vind je de belangrijkste inhoud.',
        //    placement: 'top',
        //},
        //{
        //    target: 'footer',
        //    content: 'In de footer vind je extra links, contactinformatie en andere nuttige details.',
        //    placement: 'top',
        //},
    ];

    useEffect(() => {
        const loadSavedState = () => {
            const savedStep = localStorage.getItem('tourStep');
            const savedRunTour = localStorage.getItem('runTour');

            if (savedStep !== null) {
                const parsedStep = parseInt(savedStep, 10);
                setStepIndex(parsedStep);
            }

            if (savedRunTour === 'true' || savedRunTour == null) {
                setRunTour(true);
            }
        };

        loadSavedState();
    }, []);

    useEffect(() => {
        const handleNavigation = () => { 
            if (runTour) {
                const currentStep = steps[stepIndex];
                if (currentStep && currentStep.path && currentStep.path !== location.pathname) {
                    navigate(currentStep.path);
                }
            }
        };

        handleNavigation();

    }, [runTour, stepIndex, location.pathname, navigate]);

    const handleJoyrideCallback = (data) => {
        const { action, status, index, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            localStorage.removeItem('tourStep');
            localStorage.setItem('runTour', 'false');
            setRunTour(false);
        } else {
            if (type === 'step:after') {
                let nextStepIndex = index;

                if (action === 'prev') {
                    nextStepIndex--; 
                } else {
                    nextStepIndex++;
                }
                localStorage.setItem('tourStep', nextStepIndex.toString());
                setStepIndex(nextStepIndex);
            }
        }
    };

    return (
        <>
            <Joyride
                steps={steps}
                run={runTour}
                stepIndex={stepIndex}
                continuous={true}
                showProgress={true}
                showSkipButton={false}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        primaryColor: '#98678f',
                        textColor: '#333',
                        zIndex: 1000,
                    },
                    tooltip: {
                        width: '700px',
                        padding: '20px',
                    },
                    tooltipContainer: {
                        textAlign: 'left',
                        padding: '0px',
                        margin: '0px',
                    },
                    tooltipContent: {
                        padding: '0px',
                        margin: '0px',
                        fontSize: '16px',
                    },
                    buttonClose: {
                        display: 'none'
                    },
                    buttonNext: {
                        width: '140px',
                        backgroundColor: '#98678f',
                        color: '#fff',
                        border: 'none',
                        outline: 'none',
                        padding: '10px 15px',
                    },
                    buttonBack: {
                        width: '140px',
                        backgroundColor: '#f1f1f1',
                        marginRight: 10,
                        color: '#666',
                        padding: '10px 15px',
                    },
                    buttonSkip: {
                        width: '140px',
                        float: 'left',
                        backgroundColor: '#f1f1f1',
                        marginRight: 10,
                        color: '#666',
                    },
                    floater: {
                        arrow: {
                            length: 3,
                            spread: 2
                        }
                    }
                }}
            />
        </>
    );
};

export default GuideTour;