import React, { useState, useCallback, useMemo } from 'react';
import { RiFilterOffFill } from "react-icons/ri";

const SearchComponent = ({ data, onFilteredItemsChange, title = 'Filter'}) => {
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = useMemo(() => {
        if (!data) return [];

        return data.filter(item =>
            item && item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
        );
    }, [data, filterText]);

    const handleFilter = useCallback((text) => {
        setFilterText(text);
        setResetPaginationToggle(false);
    }, []);

    const handleClear = useCallback(() => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    }, [filterText, resetPaginationToggle]);


    React.useEffect(() => {
        onFilteredItemsChange(filteredItems, resetPaginationToggle);
    }, [filteredItems, resetPaginationToggle, onFilteredItemsChange]);

    return (
        <div className="search-container">
            <button type="button" className="search-button" onClick={handleClear}>
                <RiFilterOffFill />
            </button>
            <input
                id="search"
                type="text"
                placeholder={title}
                aria-label="Search Input"
                className="search-input"
                value={filterText}
                onChange={e => handleFilter(e.target.value)}
            />
        </div>
    );
};

export default SearchComponent;