import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { GoTasklist } from "react-icons/go";
import { useTranslation } from 'react-i18next';
import '../styling/Tasks.css'; 

const Tasks = () => {
    const { setActivePage } = useOutletContext();
    const { t : translate } = useTranslation();


    useEffect(() => {
        setActivePage(translate('Tasks'));
         
    }, [setActivePage]);


    return (
        <div className="empty-state">
            <GoTasklist className="task-listicon" />
            <p>{translate('No Tasks found')}</p>
            <a className="subtext" href="#">{translate('Create a new task.')}</a>
            {translate('common.Item') }
        </div>
    );
};
 
export default Tasks;