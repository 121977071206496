import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../utils/axiosConfig';
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

const Employee = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const employeeId = location.state?.employeeId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [departments, setDepartments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [managers, setManagers] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const fetchDepartments = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/departments');
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching department:', error);
        }
    }, []);

    const fetchLocations = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/locations');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    }, []);

    const fetchManagers = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/managers');
            setManagers(response.data);
        } catch (error) {
            console.error('Error fetching managers:', error);
        }
    }, []);

    useEffect(() => {
        setActivePage(translate('Employee'));
        fetchDepartments();
        fetchLocations();
        fetchManagers();

        const fetchData = async () => {
            try {
                setLoading(true);
                if (employeeId > 0) {
                    const response = await axiosInstance.get('/employee/' + employeeId);
                    setInputValues(response.data);
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchData();
    }, [setActivePage, translate, employeeId, fetchDepartments, fetchLocations, fetchManagers]);



    const handleCancel = () => {
        navigate('/settings/configuration?tab=' + translate('employees'));
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Basic details validation
        if (!inputValues.firstname?.trim()) {
            errors.firstname = translate('First name is required');
            isValid = false;
        }

        if (!inputValues.lastname?.trim()) {
            errors.lastname = translate('Last name is required');
            isValid = false;
        }

        if (!inputValues.preferredname?.trim()) {
            errors.preferredname = translate('Preferred name is required');
            isValid = false;
        }

        if (!inputValues.email?.trim()) {
            errors.email = translate('Work email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
            errors.email = translate('Please enter a valid work email address');
            isValid = false;
        }

        if (inputValues.emailpersonal?.trim() && !/\S+@\S+\.\S+/.test(inputValues.emailpersonal)) {
            errors.emailpersonal = translate('Please enter a valid personal email address');
            isValid = false;
        }

        // Employee details validation
        if (!inputValues.employeenr?.trim()) {
            errors.employeenr = translate('Employee number is required');
            isValid = false;
        }

        if (!inputValues.departmentid) {
            errors.departmentid = translate('Department is required');
            isValid = false;
        }

        if (!inputValues.locationid) {
            errors.locationid = translate('Location is required');
            isValid = false;
        }

        if (!inputValues.managerid) {
            errors.managerid = translate('Manager selection is required');
            isValid = false;
        }

        if (!inputValues.employed) {
            errors.employed = translate('Employment date is required');
            isValid = false;
        }  
        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/employee', inputValues);

            const employeeId = response.data;
            setSuccessMessage(translate('Employee successfully saved!'));
            setTimeout(() => setSuccessMessage(''), 2500);
            setLoading(false);
            navigate('/settings/employee', { state: { employeeId: employeeId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    }; 


    const handleDelete = () => {
        console.log(inputValues);
        setSelectedEmployee(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedEmployee) {
            console.log(`Employee ${selectedEmployee.name} is being deleted`);
            await axiosInstance.delete('/employee/' + selectedEmployee.id);
            navigate('/settings/configuration?tab=' + translate('employees'));
        }
        setIsModalDeleteOpen(false);
    };


    if (loading) return <div>{translate('Loading...')}</div>; 

    return (
        <>
            {successMessage && (
                <div
                    className={`success-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div
                    className={`error-alert ${isVisible ? 'visible' : ''}`}
                >
                    <FiAlertCircle /> {error}
                </div>
            )}


            <form onSubmit={handleSubmit}>

            <div className="row">
                <div className="col-2 pt-1">
                    <strong>Basic details</strong>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="firstname">{translate('Firstname')}:</label>
                                <input
                                    type="text"
                                    id="firstname"
                                    name="firstname"
                                    value={inputValues.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="lastname">{translate('Lastname')}:</label>
                                <input
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    value={inputValues.lastname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="preferredname">{translate('Preferred name')}:</label>
                                <input
                                    type="text"
                                    id="preferredname"
                                    name="preferredname"
                                    value={inputValues.preferredname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="email">{translate('Work email')}:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={inputValues.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="emailpersonal">{translate('Personal email')}:</label>
                                <input
                                    type="email"
                                    id="emailpersonal"
                                    name="emailpersonal"
                                    value={inputValues.emailpersonal}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-2 pt-1">
                    <strong>Employee</strong>
                </div>
                <div className="col-6">

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="employeenr">{translate('Employee nr')}:</label>
                                <input
                                    type="text"
                                    id="employeenr"
                                    name="employeenr"
                                    value={inputValues.employeenr}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="departmentid">{translate('Department')}</label>
                                <select
                                    value={inputValues.departmentid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="departmentid"
                                    id="departmentid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {departments.map((department) => (
                                        <option key={department.id} value={department.id}>
                                            {department.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="locationid">{translate('Location')}</label>
                                <select
                                    value={inputValues.locationid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="locationid"
                                    id="locationid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    {locations.map((location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="managerid">{translate('Manager')}</label>
                                <select
                                    value={inputValues.managerid}
                                    onChange={handleChange}
                                    className="form-control"
                                    name="managerid"
                                    id="managerid"
                                    required
                                >
                                    <option value="">{translate('- Choose -')}</option>
                                    <option value="0">{translate('No manager')}</option>
                                    {managers.map((manager) => (
                                        <option key={manager.userid} value={manager.userid}>
                                            {manager.fullname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="employed">{translate('Employment date')}:</label>
                                <input
                                    type="date"
                                    id="employed"
                                    name="employed"
                                    value={inputValues.employed}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                </div>



            </div>

            <hr />


            <div className="row">
                <div className="col-md-3">
                    {parseInt(inputValues.id) > 0 &&
                        <button onClick={handleDelete} className="remove-button">{translate('Delete')}</button>
                    }
                </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
            </div>


            </form>
            










            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove employee')}
                message={translate('Are you sure you want to delete this employee "{{employeeName}}"?', { employeeName: selectedEmployee?.firstname + ' ' + selectedEmployee?.lastname })}
            />
        </>
    );
};

export default Employee;