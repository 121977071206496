import axios from 'axios';

const apiUrl = 'https://app.month1.nl/api';// process.env.REACT_APP_API_URL || 'http://localhost:5814';

const instance = axios.create({
    baseURL: apiUrl
});
 

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Probeer de token te vernieuwen
                const accessToken = localStorage.getItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken');

                if (!accessToken || !refreshToken) {
                    throw new Error('No tokens available for refresh');
                }

                const response = await axios.post('/refresh', {
                    accesstoken: accessToken,
                    refreshtoken: refreshToken
                });

                if (response.data.accesstoken && response.data.refreshtoken) {
                    localStorage.setItem('accessToken', response.data.accesstoken);
                    localStorage.setItem('refreshToken', response.data.refreshtoken);
                }

                // Herhaal de originele request met de nieuwe token
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return instance(originalRequest);
            } catch (refreshError) {
                // Als token vernieuwing mislukt, log de gebruiker uit
                console.error('Unable to refresh token:', refreshError);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login'; // Redirect naar login pagina
                return Promise.reject(refreshError);
            }
        } 
        return Promise.reject(error);
    }
);

export default instance;