import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../utils/axiosConfig';
import { NewButton, SearchComponent, ExportComponent } from '../../../includes';
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { useTranslation } from 'react-i18next';

const Languages = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);

    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        navigate('/settings/language', { state: { languageId: row.id } });
    }, [navigate]);

    //const handleDelete = useCallback((row) => {
    //    setSelectedLanguage(row);
    //    setIsModalOpen(true);
    //}, []);

    //const handleDeleteConfirm = useCallback(() => {
    //    if (selectedLanguage) { 
    //        console.log(`Language ${selectedLanguage.title} will deleted`);
    //        navigate('/settings/language', { state: { languageId: selectedLanguage.id } });
    //    }
    //    setIsModalOpen(false);
    //}, [selectedLanguage, navigate]);

    const columns = [
        {
            name: translate('Name'),
            selector: row => <><a href="#" onClick={(e) => handleEdit(e, row)}>{row.name}</a></>,
            sortable: true,
            reorder: true,
        },
        {
            name: translate('Code'),
            selector: row => row.code,
            sortable: true,
            reorder: true,
            width: '100px',
        },
        {
            name: translate('Active'),
            cell: row => row.active === 1 ? <span>&#10003;</span> : null,
            sortable: true,
            reorder: true,
            width: '100px',
        },
    ];

    useEffect(() => {
        setActivePage(translate('Languages'));

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/languages');
                setData(response.data);
                setFilteredItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <NewButton to="/settings/language" title={translate('+ New language')} />
            <div className="table-header mb-5">
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by name')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Languages;