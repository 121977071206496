import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import '../styling/ForgotPassword.css';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/forgotpassword', { email });
            if (response.status === 200) {
                setIsSubmitted(true);
                setMessage(translate('Password reset instructions sent to your email.'));
            } else if (response.status === 404) {
                setIsSubmitted(true);
                setMessage(translate('If the email exists, a password reset link will be sent.'));
            } else {
                setMessage(translate('An unexpected error occurred. Please try again.'));
            }
        } catch (error) {
            setMessage(translate('Failed to process request. Please try again.'));
            console.error('Error:', error);
        }
    };

    return (
        <div className="forgot-password-page">
            <div className="forgot-password-container">
                <div className="forgot-password-header">
                    <span className="forgot-password-logo">Month 1</span>
                    <h1>{translate('Reset your password')}</h1>
                </div>
                {!isSubmitted ? (
                    <form onSubmit={handleSubmit} className="forgot-password-form">
                        <div className="input-group">
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={translate('Your email address')}
                                required
                            />
                        </div>
                        <button type="submit" className="reset-button">
                            {translate('Send reset instructions')}
                        </button>
                    </form>
                ) : (
                    <div className="success-message">
                        <p>{message}</p>
                        <button onClick={() => navigate('/login')} className="back-to-login-button">
                            {translate('Back to login')}
                        </button>
                    </div>
                )}
                <div className="forgot-password-footer">
                    <a href="/login">{translate('Remember your password? Log in')}</a>
                </div>
                {message && !isSubmitted && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;