import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axiosConfig';
import '../styling/ResetPassword.css';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa"; 

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [blocked, setBlocked] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { t: translate } = useTranslation();
    const [type, setType] = useState('password');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get('token');
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        } else {
            setMessage(translate('Invalid or missing reset token.'));
            setBlocked(true);
        }
    }, [location, translate]);


    const handleTogglePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage('');

        if (password !== confirmPassword) {
            setMessage(translate('Passwords do not match.'));
            setIsLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post('/resetpassword', { token, password });
            if (response.status === 200) {
                setMessage(translate('Password successfully reset. You can now log in with your new password.'));
                setTimeout(() => navigate('/login'), 3000);
            } else if (response.status === 400) {
                setMessage(translate('Invalid or expired access token.'));
                setTimeout(() => navigate('/login'), 3000);
            }
        } catch (error) {
            if (error.response) {
                setMessage(translate(error.response.data.message || 'An error occurred. Please try again.'));
            } else {
                setMessage(translate('An unexpected error occurred. Please try again.'));
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="reset-password-page">
            <div className="reset-password-container">
                <div className="reset-password-header">
                    <span className="login-logo">Month 1</span>
                    <h1>{translate('Reset Your Password')}</h1>
                </div>
                 
                {message && <p className="message">{message}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="password">{translate('New Password')}</label>
                        <input
                            type={type}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span className="passwordvisibility" style={{ top: '33px' }} onClick={handleTogglePassword}>
                            {type === 'text' && (
                                <FaRegEye />
                            )}
                            {type === 'password' && (
                                <FaRegEyeSlash />
                            )}
                        </span>
                    </div>
                    <div className="input-group">
                        <label htmlFor="confirmPassword">{translate('Confirm New Password')}</label>
                        <input
                            type={type}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button className="set-button" type="submit" disabled={isLoading || blocked}>
                        {isLoading ? translate('Resetting...') : translate('Reset Password')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;