import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom'; 
import axiosInstance from '../../utils/axiosConfig'; 
import { FiInfo } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";

const Preboardingdetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate, i18n } = useTranslation();
    const [templates, setTemplates] = useState([]);
    const [countries, setCountries] = useState([]);
    const flowid = location.state?.flowid ?? 0;
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isPreferredNameManuallySet, setIsPreferredNameManuallySet] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [newNationality, setNewNationality] = useState('');

    const [inputValues, setInputValues] = useState({
        currentstep: 1,
        typeid: 1,
        template: 0,
        id: 0,
        startdate: '',
        name: '',
        firstname: '',
        lastname: '',
        preferredname: '',
        gender: '',
        dayofbirth: '',
        placeofbirth: '',
        civilstatus: '',
        personalemail: '',
        personalphone: '',
        nationality: '',
    }); 


    const fetchTemplates = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/template/list/1');
            setTemplates(response.data);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    }, []);

    const fetchCountries = useCallback(async () => {
        try {
            const response = await axiosInstance.get('/country/list/' + i18n.language);
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }, [i18n.language]);

    const fetchItem = useCallback(async () => {
        try {
            setLoading(true); 
            const response = await axiosInstance.get('/flow/getboarding/' + flowid);

            // Convert null values to empty strings before setting state
            const sanitizedData = Object.fromEntries(
                Object.entries(response.data).map(([key, value]) => [key, value ?? ''])
            );

            setInputValues(sanitizedData);
            setActivePage(translate('Preboarding workflow - ' + response.data.name));
        } catch (err) {
            console.error('An error occurred while retrieving the data :', err);
        } finally {
            setLoading(false);
        }
    }, [flowid, setActivePage, translate]);

    useEffect(() => {
        setActivePage(translate('New preboarding'));
        fetchTemplates();
        fetchCountries();
        if (flowid !== 0) {
            fetchItem();
        } else {
            setLoading(false);
        }
    }, [setActivePage, translate, fetchTemplates, fetchCountries, fetchItem, flowid]);

    const handleCancel = useCallback(() => {
        navigate('/boarding/preboarding');
    }, [navigate]);


    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'preferredname') {
            setIsPreferredNameManuallySet(value !== '');
        }
    }, []);



    useEffect(() => {
        if (!isPreferredNameManuallySet) {
            const { firstname, lastname } = inputValues;
            let autoPreferredName = '';
            if (firstname && lastname) {
                autoPreferredName = `${firstname} ${lastname}`.trim();
            }
            setInputValues(prevState => ({
                ...prevState,
                preferredname: autoPreferredName
            }));
        }
    }, [inputValues.firstname, inputValues.lastname, isPreferredNameManuallySet]);

     

    const handleSave = useCallback(async () => {
        try {
            setLoading(true);

            // Convert empty strings back to null for the API
            const apiData = Object.fromEntries(
                Object.entries(inputValues).map(([key, value]) => [key, value === '' ? null : value])
            );

            const response = await axiosInstance.post('/flow/setboarding', { ...apiData, currentstep: 1 });
            setLoading(false);


            if (response.status === 200) {
                const flowId = response.data; 
                setSuccessMessage(translate('Preboarding flow successfully saved!'));
                setIsVisible(true);
                setTimeout(() => {
                    setSuccessMessage('');
                    setIsVisible(false);
                }, 2500);
                navigate('/boarding/preboarding/employee', { state: { flowid: flowId } });
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (err) {
            console.error('Error saving preboarding flow:', err);
            setLoading(false);
        }
    }, [inputValues, translate, navigate]);

    const handleAddNationality = useCallback(() => {
        const trimmedNationality = newNationality.trim();
        if (trimmedNationality) {
            const existingCountry = countries.find(country =>
                country.resident.toLowerCase() === trimmedNationality.toLowerCase()
            );

            if (existingCountry) {
                setInputValues(prevValues => ({ ...prevValues, nationality: existingCountry.resident }));
            } else {
                const newCountry = { code: Date.now().toString(), resident: trimmedNationality };
                setCountries(prevCountries => [...prevCountries, newCountry]);
                setInputValues(prevValues => ({ ...prevValues, nationality: trimmedNationality }));
            }
            setNewNationality('');
            setIsAdding(false);
        }
    }, [countries, newNationality]);

    const toggleAdding = useCallback(() => {
        setIsAdding(prev => !prev);
        setNewNationality('');
    }, []);

    if (loading) return <div>{translate('Loading...')}</div>;


    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : ''}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            {/* Flow section */}
            <div className="row">
                <div className="col-2"><strong>{translate('Flow')}</strong></div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="name">{translate('Name')}:</label>
                                <input
                                    type="text"
                                    maxLength="200"
                                    id="name"
                                    name="name"
                                    value={inputValues.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-9">
                            <div className="form-group">
                                <label htmlFor="template">{translate('Choose template')}</label>
                                <select className="form-control" id="template" name="template" value={inputValues.template} onChange={handleChange}>
                                    <option>{translate('- Choose -')}</option>
                                    {templates.map(template => (
                                        <option key={template.templateid} value={template.templateid}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="startdate">{translate('First day')}:</label>
                                <input
                                    type="date"
                                    maxLength="10"
                                    id="startdate"
                                    name="startdate"
                                    value={inputValues.startdate}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>

            <div className="row">
                <div className="col-2"><strong>{translate('Personal details')}</strong></div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="firstname">{translate('Firstname')}:</label>
                                <input
                                    type="text"
                                    maxLength="200"
                                    id="firstname"
                                    name="firstname"
                                    value={inputValues.firstname}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="lastname">{translate('Lastname')}:</label>
                                <input
                                    type="text"
                                    maxLength="200"
                                    id="lastname"
                                    name="lastname"
                                    value={inputValues.lastname}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="preferredname">{translate('Preferred name')}:</label>
                                <input
                                    type="text"
                                    maxLength="200"
                                    id="preferredname"
                                    name="preferredname"
                                    value={inputValues.preferredname}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="gender">{translate('Gender')}</label>
                                <select className="form-control" id="gender" name="gender" value={inputValues.gender} onChange={handleChange}>
                                    <option value="">{translate('- Choose -')}</option>
                                    <option value="Male">{translate('Male')}</option>
                                    <option value="Female">{translate('Female')}</option>
                                    <option value="Other">{translate('Other')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="dayofbirth">{translate('Day of birth')}:</label>
                                <input
                                    type="date"
                                    id="dayofbirth"
                                    name="dayofbirth"
                                    maxLength="10"
                                    value={inputValues.dayofbirth}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="form-group">
                                <label htmlFor="placeofbirth">{translate('Place of birth')}:</label>
                                <input
                                    type="text"
                                    id="placeofbirth"
                                    name="placeofbirth"
                                    maxLength="200"
                                    value={inputValues.placeofbirth}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group" style={{ position: 'relative' }} >
                                <label htmlFor="nationality">{translate('Nationality')}:</label>
                                {!isAdding ? (
                                    <div>
                                        <select
                                            value={inputValues.nationality}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={{ width: 'calc(100% - 38px)' }}
                                            name="nationality"
                                            id="nationality"
                                        >
                                            <option value="">{translate('- Choose -')}</option>
                                            {countries.map((country) => (
                                                <option key={country.code} value={country.code}>
                                                    {country.resident}
                                                </option>
                                            ))}
                                        </select>
                                        <CiSquarePlus
                                            style={{ fontSize: '28px', position: 'absolute', right: '3px', top: '28px', cursor: 'pointer' }}
                                            onClick={toggleAdding}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <input
                                            type="text"
                                            value={newNationality}
                                            maxLength="200"
                                            onChange={(e) => setNewNationality(e.target.value)}
                                            placeholder={translate('Add option')}
                                            style={{ width: 'calc(100% - 228px)' }}
                                            autoFocus
                                        />
                                        <button
                                            onClick={handleAddNationality}
                                            className="save-button"
                                            style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                        >
                                            {translate('Add')}
                                        </button>
                                        <button
                                            onClick={toggleAdding}
                                            className="cancel-button"
                                            style={{ width: '100px', height: '38px', lineHeight: '16px', marginTop: '0px' }}
                                        >
                                                {translate('Cancel')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="civilstatus">{translate('Civil status')}</label>
                                <select className="form-control" id="civilstatus" name="civilstatus" value={inputValues.civilstatus} onChange={handleChange}>
                                    <option value="">{translate('- Choose -')}</option>
                                    <option value="Single">{translate('Single')}</option>
                                    <option value="Married">{translate('Married')}</option>
                                    <option value="Divorced">{translate('Divorced')}</option>
                                    <option value="Widowed">{translate('Widowed')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-2"><strong>{translate('Contact')}</strong></div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="personalemail">{translate('Personal email')}:</label>
                                <input
                                    type="email"
                                    maxLength="200"
                                    id="personalemail"
                                    name="personalemail"
                                    value={inputValues.personalemail}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="personalphone">{translate('Phone number')}:</label>
                                <input
                                    type="text"
                                    maxLength="14"
                                    id="personalphone"
                                    name="personalphone"
                                    value={inputValues.personalphone}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>

            <div className="row">
                <div className="col-md-8">
                    <button onClick={handleSave} className="save-button">{translate('Next')}</button>
                    <button onClick={handleCancel} className="cancel-button">{translate('Cancel')}</button>
                </div>
            </div>
        </>
    );
};

export default Preboardingdetails;