export const actionTypes = {
    messages: [
        { value: 'eml', label: 'Email message' },
        { value: 'slk', label: 'Slack message' },
        { value: 'tms', label: 'Team message' }
    ],
    tasks: [
        { value: 'tsk', label: 'Task' }
    ],
    integrations: [
        { value: 'nmb', label: 'Send details to Nmbrs' },
        { value: 'afs', label: 'Send details to Afas' },
        { value: 'prs', label: 'Send details to Personio' },
        { value: 'rcr', label: 'Send details to Recruitee' }
    ],
    checks: [
        { value: 'rci', label: 'Received response copy ID' },
        { value: 'rec', label: 'Received signed employment contract' }
    ]
};