import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrello, FiUser, FiSettings } from 'react-icons/fi';
import { FaClipboardList } from "react-icons/fa6";
import { VscSettings } from "react-icons/vsc";
import { TiMessages } from "react-icons/ti";
import { RiFlowChart } from "react-icons/ri";
import { LiaLanguageSolid } from "react-icons/lia";
import { MdSchool } from "react-icons/md";

const useSidebarItems = () => {
    const { t: translate } = useTranslation();

    return [
        {
            name: translate('menu.Tasks'),
            icon: <FiTrello />,
            path: '/tasks'
        },
        {
            name: translate('menu.Boarding'),
            icon: <FiUser />,
            subItems: [
                { icon: <FaClipboardList />, name: translate('menu.Preboarding'), path: '/boarding/preboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Onboarding'), path: '/boarding/onboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Reboarding'), path: '/boarding/reboarding' },
                { icon: <FaClipboardList />, name: translate('menu.Offboarding'), path: '/boarding/offboarding' }
            ],
            isOpen: false,
            path: '/boarding'
        },
        {
            name: translate('menu.E-Learning'),
            icon: <MdSchool />,
            path: '/elearning'
        },
        {
            name: translate('menu.Settings'),
            icon: <FiSettings />,
            subItems: [
                { icon: <VscSettings />, name: translate('menu.Configuration'), path: '/settings/configuration' },
                { icon: <LiaLanguageSolid />, name: translate('menu.Languages'), path: '/settings/languages' },
                { icon: <TiMessages />, name: translate('menu.Messages'), path: '/settings/messages' },
                { icon: <RiFlowChart />, name: translate('menu.Boardingflows'), path: '/settings/boarding' }
            ],
            isOpen: false,
            path: '/configuration'
        },
    ];
};

export default useSidebarItems;