import React from 'react';
import ReactDOM from 'react-dom';
import '../styling/PopupModal.css';

const PopupModal = ({ isOpen, onClose, children, height = "200px", width = "200px" }) => {
    if (!isOpen) return null;
     
    const modalStyle = {
        height: height,
        width: width
    };

    return ReactDOM.createPortal(
        <div className="popup-modal-overlay">
            <div className="popup-modal-content" style={modalStyle}>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default PopupModal;