import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';


const NewButton = ({ to = '/new-page', title = '+ New item' }) => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();

    return (
        <button
            onClick={() => navigate(to)}
            className="new-button"
            aria-label={title}
            title={title}
        >{title}
        </button>
    );
};

export default NewButton;