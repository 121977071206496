import React from 'react';
import '../styling/PopupModal.css';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    const { t: translate } = useTranslation();
    if (!isOpen) return null;

    return (
        <div className="popup-modal-overlay">
            <div className="popup-modal-content">
                <h2 className="popup-modal-title">{title}</h2>
                <p className="popup-modal-message">{message}</p>
                <div className="popup-modal-actions">
                    <button className="popup-modal-button modal-button-primary" onClick={onConfirm}>
                        {translate('Yes')}
                    </button>
                    <button className="popup-modal-button modal-button-secondary" onClick={onClose}>
                        {translate('No')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;