import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, Route, Routes, Outlet } from 'react-router-dom';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiSettings, FiLogOut } from 'react-icons/fi';
import axiosInstance from '../utils/axiosConfig';
import '../styling/Dashboard.css';
import useSidebarItems from './useSidebarItems';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import GuideTour from '../utils/GuideTour'

const Dashboard = ({ activePage, setActivePage }) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('My to-do');
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [languageData, setLanguages] = useState(null);
    const userMenuRef = useRef(null);
    const sidebarItems = useSidebarItems();  // Gebruik de hook hier
    const [sidebarState, setSidebarState] = useState(sidebarItems);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const { i18n } = useTranslation();

    const toggleSubMenu = (index) => {
        const newSidebarState = [...sidebarState];
        newSidebarState[index].isOpen = !newSidebarState[index].isOpen;
        setSidebarState(newSidebarState);
    };
    const languageToCountryCode = {
        'en': 'gb',
        // voeg hier andere mappings toe indien nodig
    };

    const fetchLanguages = async () => {
        try {
            const response = await axiosInstance.get('/languages/active');
            setLanguages(response.data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await axiosInstance.get('/user');
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const subscribeToPushNotifications = async () => {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BHpZPoWy7uBVoYZxrP3dBh2DJ6QFCn3j7/uLNp3Ue4ND Private Key: dXc7wRjGSy2IBWn0oVy93InZHkdixLFB0U0ajQ7Y3YU=BIRDTuL26H1gXXt58hQq6370lIepe89iJBb3oKUvjo3Gdj9inkXyQBFCgwYiSKDo55GqdIDHvGun8KWmWqUIV2Ez2EiXG7WhlMpq3tuYHSqmzM+YHijybw4qMTL9K1kwi0=')
        });

        await fetch('/save-subscription.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subscription),
        });

        setIsSubscribed(true);
    };

    useEffect(() => {
        fetchLanguages();
        fetchUserData();

        if ('serviceWorker' in navigator && 'PushManager' in window) {
            navigator.serviceWorker.ready.then(registration => {
                registration.pushManager.getSubscription().then(subscription => {
                    setIsSubscribed(!!subscription);
                });
            });
        }
    }, []);

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        navigate(0);
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/login');
    };

    const handleSettings = () => {  
        navigate('/settings/account', { state: { accountid: userData.userId } });
    };

    const toggleSidebar = () => {
        setSidebarCollapsed(!isSidebarCollapsed);
    };

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setIsUserMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderSidebarItem = (item, index) => {
        if (item.subItems) {
            return (
                <div key={index}>
                    <button
                        className={`sidebar-item ${activeTab === item.name ? 'active' : ''}`}
                        title={item.name}
                        onClick={() => toggleSubMenu(index)}
                    >
                        <span className="sidebar-icon">{item.icon}</span>
                        <span className="sidebar-text">{item.name}</span>
                        {item.isOpen ? <FiChevronDown /> : <FiChevronRight />}
                    </button>
                    {item.isOpen && (
                        <div className="sub-menu">
                            {item.subItems.map((subItem, subIndex) => (
                                <button
                                    key={subIndex}
                                    title={subItem.name}
                                    className={`sidebar-item sub-item ${activeTab === subItem.name ? 'active' : ''}`}
                                    onClick={() => {
                                        setActiveTab(subItem.name);
                                        navigate(subItem.path);
                                    }}
                                >
                                    <span className="sidebar-icon">{subItem.icon}</span>
                                    <span className="sidebar-text">{subItem.name}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <button
                    key={index}
                    title={item.name}
                    className={`sidebar-item ${activeTab === item.name ? 'active' : ''}`}
                    onClick={() => {
                        setActiveTab(item.name);
                        navigate(item.path);
                    }}
                >
                    <span className="sidebar-icon">{item.icon}</span>
                    <span className="sidebar-text">{item.name}</span>
                </button>
            );
        }
    };



    return (
        <div className="dashboard">
            <aside className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-header">
                    <div className="logo">Month 1</div>
                    <button onClick={toggleSidebar} className="toggle-sidebar">
                        {isSidebarCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
                    </button>
                </div>
                <nav>
                    {sidebarState.map((item, index) => renderSidebarItem(item, index))}
                </nav>
            </aside>
            <main className="main-content">
                <header className="main-header">
                    <h1>{activePage}</h1>
                    <div className="header-actions">
                        <div className="user-menu-container" ref={userMenuRef}>
                            <button onClick={toggleUserMenu} className="user-icon">{userData ? userData.initials : 'UU'}</button>
                            {isUserMenuOpen && (
                                <div className="user-menu">
                                    <div className="user-info">
                                        <span className="user-name">{userData ? userData.fullname : 'Loading...'}</span>
                                        <span className="user-email">{userData ? userData.email : 'Loading...'}</span>
                                    </div>

                                    <div>


                                        {languageData.map((language) => (
                                            <ReactCountryFlag
                                                key={language.code}
                                                className="flag"
                                                countryCode={languageToCountryCode[language.code] || language.code}
                                                onClick={() => changeLanguage(language.code)}
                                                svg
                                                style={{
                                                    width: '24px',
                                                    height: '18px',
                                                }}
                                                title={language.name}
                                            />
                                        ))}

                                    </div>
                                    <button className="menu-item" onClick={handleSettings}><FiSettings /> {translate('menu.Settings')}</button>
                                    <button className="menu-item" onClick={handleLogout}><FiLogOut /> {translate('menu.Logout')}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </header>
                <div className="content-area">
                    <Outlet context={{ setActivePage }} />
                </div>
            </main>
            <GuideTour />
        </div>
    );


};

export default Dashboard;