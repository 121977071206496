import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { GoTasklist } from "react-icons/go";
import { useTranslation } from 'react-i18next'; 

const Elearnings = () => {
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();


    useEffect(() => {
        setActivePage(translate('Elearning'));

    }, [setActivePage]);


    return (
        <div>Elearning </div>
    );
};

export default Elearnings;