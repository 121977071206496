import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../utils/axiosConfig';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NewButton, SearchComponent, ExportComponent } from '../../../includes';
import { FaRegEdit } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Messages = () => {
    const [data, setData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const navigate = useNavigate();
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();

    const handleFilteredItemsChange = useCallback((newFilteredItems, newResetPaginationToggle) => {
        setFilteredItems(newFilteredItems);
        setResetPaginationToggle(newResetPaginationToggle);
    }, []);


    const handleEdit = useCallback((e, row) => {
        e.preventDefault();
        navigate('/settings/message', { state: { messageId: row.messageId } });
    }, [navigate]);
    
    const columns = [
        {
            name: translate('Subject'), 
            selector: row => <><a href="#" onClick={(e) => handleEdit(e, row)}>{row.subject}</a></>,
            sortable: true,
            reorder: true,
        },
        {
            name: translate('Last changed'),
            selector: row => row.lastchanged,
            sortable: true,
            reorder: true,
            width: '200px',
        }, 
    ];

    useEffect(() => {
        setActivePage(translate('Messages')); 

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('/messages');
                setData(response.data);
                setFilteredItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error while try to load the data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [setActivePage]);

    if (loading) {
        return <div>{translate('Loading...')}</div>;
    }

    return (
        <>
            <div className="table-header">
                <NewButton to="/settings/message" title={translate('+ New message')} />
                <SearchComponent
                    data={data}
                    onFilteredItemsChange={handleFilteredItemsChange}
                    title={translate('Filter by subject')}
                />
                {/*<ExportComponent data={filteredItems} />*/}
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
                striped
                highlightOnHover
            />
        </>
    );
};

export default Messages;