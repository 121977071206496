import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../utils/axiosConfig';
import ConfirmationModal from '../../../includes/ConfirmationModal';
import { FiInfo, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Location = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const locationId = location.state?.locationId ?? 0;
    const { setActivePage } = useOutletContext();
    const { t: translate } = useTranslation();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [inputValues, setInputValues] = useState({
        id: 0,
        name: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setActivePage(translate('Location'));

        const fetchData = async () => {
            try {
                setLoading(true);
                if (locationId > 0) {
                    const response = await axiosInstance.get('/location/' + locationId);
                    setInputValues(response.data);

                    if (response.data.name.length > 2) {
                        getImage(response.data.name);
                    }
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchData();
    }, [setActivePage, translate, locationId]);

    const getImage = async (value) => {
        if (value.length > 2) {
            try {
                const response = await axiosInstance.get('/location/image/' + value);
                if (response.data && response.data.imageUrl) {
                    setImageUrl(response.data.imageUrl);
                }
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
    }

    const handleCancel = () => {
        navigate('/settings/configuration?tab=' + translate('locations'));
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear validation error when user starts typing
        if (validationErrors[name]) {
            setValidationErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }

        if (name === 'name' && value.length > 2) {
            getImage(value);
        }
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        if (!inputValues.name?.trim()) {
            errors.name = translate('Location name is required');
            isValid = false;
        } else if (inputValues.name.trim().length < 2) {
            errors.name = translate('Location name must be at least 2 characters long');
            isValid = false;
        } else if (inputValues.name.trim().length > 200) {
            errors.name = translate('Location name cannot be longer than 200 characters');
            isValid = false;
        }

        setValidationErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            setLoading(true);
            const response = await axiosInstance.post('/location', inputValues);

            const locationId = response.data;
            setSuccessMessage(translate('Location successfully saved!'));
            setLoading(false);
            setTimeout(() => setSuccessMessage(''), 2500);
            navigate('/settings/location', { state: { locationId: locationId } });
        } catch (err) {
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Validation error occurred';
                setError(translate(errorMessage));
                setTimeout(() => setError(''), 2500);
                setLoading(false);
            }
        }
    };

    const handleDelete = () => {
        setSelectedLocation(inputValues);
        setIsModalDeleteOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (selectedLocation) {
            await axiosInstance.delete('/location/' + selectedLocation.id);
            navigate('/settings/configuration?tab=' + translate('locations'));
        }
        setIsModalDeleteOpen(false);
    };

    const imageContainerStyle = {
        width: '300px',
        height: '300px',
        backgroundImage: imageUrl ? `linear-gradient(90deg, #f5f5f5 15%, rgba(0,212,255,0) 90%), url("${imageUrl}")` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'right center',
        float: 'right',
        marginTop: '20px',
    };

    if (loading) return <div>{translate('Loading...')}</div>;

    return (
        <>
            {successMessage && (
                <div className={`success-alert ${isVisible ? 'visible' : 'xx'}`}>
                    <FiInfo /> {successMessage}
                </div>
            )}

            {error && (
                <div className={`error-alert ${isVisible ? 'visible' : ''}`}>
                    <FiAlertCircle /> {error}
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="name">{translate('Name')}:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={inputValues.name}
                                onChange={handleChange}
                                className={`form-control ${validationErrors.name ? 'is-invalid' : ''}`}
                                maxLength="200"
                                required
                            />
                            {validationErrors.name && (
                                <div className="invalid-feedback">
                                    {validationErrors.name}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-6">
                        <div style={imageContainerStyle}></div>
                    </div>
                </div>
                <hr />

                <div className="row">
                    <div className="col-md-3">
                        {parseInt(inputValues.id) > 0 &&
                            <button type="button" onClick={handleDelete} className="remove-button">
                                {translate('Delete')}
                            </button>
                        }
                    </div>
                    <div className="col-md-9">
                        <button type="submit" className="save-button">
                            {translate('Save')}
                        </button>
                        <button type="button" onClick={handleCancel} className="cancel-button">
                            {translate('Cancel')}
                        </button>
                    </div>
                </div>
            </form>

            <ConfirmationModal
                isOpen={isModalDeleteOpen}
                onClose={() => setIsModalDeleteOpen(false)}
                onConfirm={handleDeleteConfirm}
                title={translate('Remove location')}
                message={translate('Are you sure you want to delete this location "{{locationName}}"?', { locationName: selectedLocation?.name })}
            />
        </>
    );
};

export default Location;